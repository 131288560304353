import { render, staticRenderFns } from "./SidenavMenu.vue?vue&type=template&id=57d2ce76&"
import script from "./SidenavMenu.vue?vue&type=script&lang=js&"
export * from "./SidenavMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/tim/data/projects/admintools-vuejs/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57d2ce76')) {
      api.createRecord('57d2ce76', component.options)
    } else {
      api.reload('57d2ce76', component.options)
    }
    module.hot.accept("./SidenavMenu.vue?vue&type=template&id=57d2ce76&", function () {
      api.rerender('57d2ce76', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vendor/libs/sidenav/SidenavMenu.vue"
export default component.exports